<template>
  <div class="contact">
    <div class="contact-box">
      <h2 class="box-title">联系我们</h2>
      <el-row :gutter="50">
        <el-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13" class="box-form">
          <el-form ref="formData" :model="formData" :rules="formRules" label-width="0">
            <p>欢迎您的访问，请留下您的信息与问题，<br>方便我们及时反馈</p>

            <el-row :gutter="20">

              <el-col :span="12" class="form-item">
                <el-form-item prop="name">
                  <el-input placeholder="姓名：" v-model="formData.name" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="form-item">
                <el-form-item prop="mobile">
                  <el-input placeholder="电话：" v-model="formData.mobile" style="width: 100%;"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" class="form-item">
                <el-form-item prop="email">
                  <el-input v-model="formData.email" placeholder="邮箱："></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" class="form-item">
                <el-form-item prop="project">
                  <el-input type="textarea" v-model="formData.project" placeholder="留言内容：" :rows="4"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" style="text-align: right">
                <el-button type="warning" @click="onSubmit">确认发送</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="11" :lg="11" :xl="11" class="box-info">
          <div class="grid-content">
            <div class="address-info" style="display: flex;">
              <p><i class="el-icon-map-location"> &nbsp;</i>
              </p>
              <p>公司地址：<br>
                北京市朝阳区朝阳北路103号青年公社
                102号楼528室
              </p>
            </div>
            <div class="address-info" style="display: flex;">
              <p><i class="el-icon-office-building"></i> &nbsp;
              </p>
              <p>邮编：100123</p>
            </div>
            <div class="contact-info" style="display: flex;">
              <p><br>
                <i class="el-icon-message"></i> &nbsp;<br>
                <i class="el-icon-phone-outline"></i>
              </p>
              <p>联系方式：<br>
                info@amazegg.cn <br>
                010-65578228 / 13426166116
              </p>
            </div>
          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
// import { MessageBox} from 'element-ui'
import {MessageBox} from "element-ui";

export default {
  data() {
    // var checkphone = (rule, value, callback) => {
    //   // let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
    //   if (value === '') {
    //     callback(new Error('请输入手机号'))
    //   } else if (!this.isCellPhone(value)) { // 引入methods中封装的检查手机格式的方法
    //     callback(new Error('请输入正确的手机号!'))
    //   } else {
    //     callback()
    //   }
    // }
    // 手机号验证
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    return {
      formData: {
        name: '',
        mobile: '',
        email: '',
        project: ''
      },
      formRules: {
        name: [
          {type: 'string', required: true, message: '请输入您的姓名', trigger: 'blur'}
        ],
        mobile: [
          {type: 'string', required: true, validator: validatePhone, message: '请填写您的联系电话', trigger: 'blur'}
        ],
        email: [
          {type: 'email', required: false, message: '请填写正确的邮箱', trigger: 'blur'}
        ],
        project: [
          {type: 'string', required: true, message: '请填写留言内容', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    onSubmit() {
      // console.log('submit!');
      let that = this;
      this.$refs['formData'].validate((valid) => {
        if (valid) {
          // alert('submit!');
          console.log(that.formData);
          //*
          that.$http.post('/index/article/save_info', that.formData)
              .then(function (response) {
                console.log(response);
                MessageBox.alert('您的留言已提交，我们会尽快和您联系！', {})
                that.$refs['formData'].resetFields();
              })
              .catch(function (error) {
                console.log(error);
              });
//*/
        } else {
          //console.log('error submit!!');
          return false;
        }
      });

    }
  }
}
</script>


<style scoped lang="scss">
.contact {
  position: relative;
  background: #e3e3e3;
  background-size: cover;
  min-height: 740px;
  height: calc(100vh - 350px);
}

.contact-box {
  width: 960px;
  //height: 545px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff url("../assets/contact_top_bg.jpg") no-repeat left top;
  box-shadow: 2px 2px 6px rgba(3, 10, 24, 0.1);
  border-radius: 10px;
  padding: 120px 40px 40px 40px;

  h2.box-title {
    position: absolute;
    top: 50px;
    right: 35px;
    font-size: 36px;
    line-height: 1;
    margin: 0;
    color: #fff;
    font-weight: 400;
    opacity: 0.5;
  }

  .box-form {
    //padding: 20px 50px;
    //font-size: 20px;
  }

  .box-info {
    border-left: 1px solid #cecece;
    height: 360px;
    padding-top: 1em;
  }

  .form-item {
    //margin-bottom: 20px;

    &:last-child {
      //margin-bottom: 0;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .address-info {
    //border-top: 1px solid #cecece;
    //margin-top: 30px;
    //padding-top: 30px;
  }

  .contact-info {
    border-top: 1px solid #cecece;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;

  }

}

@media only screen and (max-width: 991px) {

  .contact-box {
    max-width: 600px;
    padding: 120px 50px 40px 50px;

    .box-info {
      border-left: none;
      height: auto;

      .grid-content {
        border-top: 1px solid #cecece;
        margin-top: 10px;
        padding-top: 20px;
      }
    }

  }

}


@media only screen and (max-width: 768px) {
  .contact {
    height: auto;
    padding: 0;
  }

  .contact-box {
    width: 100%;
    max-width: 500px;
    height: auto;
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    border-radius: 0;

  }

}
</style>
